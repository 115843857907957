<script setup>
import Panel from '../common/Panel.vue';
import { PhxIcon, PhxAutocomplete } from '@phx-styleguides/pia';
import { useI18n } from 'vue-i18n';
import { useRecentTerms } from '../../composables/recentTerms';
import { computed, onMounted, useSlots, ref, watch } from 'vue';
import SearchPanelEmptyState from '../search/SearchPanelEmptyState.vue';
import SearchPanelResults from '../search/SearchPanelResults.vue';
import { useStore } from 'vuex';
import debounce from 'lodash-es/debounce';
import AlgoliaSearch, { algoliaSearch } from '../../search/algolia/AlgoliaSearch';
import { showPanel } from '@phoenix/helpers/panel-helper';
import { URL_QUERY_SEARCH_NAME } from '../../helpers/search-helper';

const props = defineProps({
  allBoutiquesImage: {
    type: Object,
    default: () => ({}),
  },
  emptyStateHitsPerType: {
    type: Object,
    default: () => ({}),
  },
});

const slots = useSlots();
const store = useStore();
const { t } = useI18n();
const { addRecentTerm } = useRecentTerms('mainNavigation');

const autocompleteRef = ref(null);
const stagedQuery = ref('');
const urlQueryTerm = ref('');

const searchLabel = t('MainNavigation.search');
const closeLabel = t('Accessibility.button.close');
const panelId = 'search-panel';
const minTermLength = 3;
const [delayForSuggestions, delayForSearch] = [600, 1000];

const suggestions = ref([]);
const searchInitialized = ref(false); // late initialization of autocomplete to avoid teleport issue

const currentQuery = computed(() => store.state.search.query);
const isQueryResultEmpty = computed(
  () =>
    !store.state.search.isLoading &&
    stagedQuery.value.length >= minTermLength &&
    stagedQuery.value === currentQuery.value &&
    store.getters['search/totalResults'] === 0
);
const hasEmptyState = computed(() => stagedQuery.value.length < minTermLength || isQueryResultEmpty.value);

const panelOptions = computed(() => ({
  overlayClass: 'panel-overlay',
  containerClass: 'panel-body',
  closeAll: true,
  updateTabsIndicator: true,
  focusOnOpen: 'search-panel-autocomplete',
  onShow: () => {
    searchInitialized.value = true;
  },
}));

const applyQuery = (term, updateUrl = true) => {
  stagedQuery.value = term;

  if (updateUrl) {
    const url = new URL(window.location);
    if (!term) {
      url.searchParams.delete(URL_QUERY_SEARCH_NAME);
    } else {
      url.searchParams.set(URL_QUERY_SEARCH_NAME, term);
    }

    window.history.pushState(null, null, url);
  }

  if (term.length < minTermLength) {
    return;
  }

  store.dispatch('search/setQuery', term);
};

const setQueryDebounced = debounce(applyQuery, delayForSearch);
const fetchSuggestions = debounce(async (term) => {
  if (!term || term.length < minTermLength) {
    suggestions.value = [];
    return;
  }
  const algoliaSuggestions = await algoliaSearch.fetchQuerySuggestions(term);
  suggestions.value = algoliaSuggestions.map((suggestion) => ({
    key: suggestion,
    value: suggestion,
  }));
}, delayForSuggestions);

const onBlur = () => {
  if (currentQuery.value && currentQuery.value === stagedQuery.value) {
    addRecentTerm(currentQuery.value);
  }
};

const onQuery = (value) => {
  if (AlgoliaSearch.isEnabled()) {
    fetchSuggestions(value);
  }
  setQueryDebounced(value);
};

const onTermClicked = (term) => {
  setCustomQuery(term);
};

const onChange = (option) => {
  if (!option && option.value) {
    return;
  }

  // prevent loading on url query set
  if (option.value === urlQueryTerm.value) {
    return;
  }

  applyQuery(option.value);
};

const setCustomQuery = (query) => {
  if (!autocompleteRef.value) {
    return;
  }
  autocompleteRef.value.setValue(query);
};

const onEnter = (value) => {
  if (!value || value.length < minTermLength) {
    return;
  }
  addRecentTerm(value);
};

onMounted(() => {
  const panel = document.getElementById(panelId);

  urlQueryTerm.value = new URLSearchParams(window.location.search).get(URL_QUERY_SEARCH_NAME);
  if (urlQueryTerm.value) {
    store.dispatch('search/setQuery', urlQueryTerm.value);
  }
  if (panel) {
    window.Phoenix.panels[panelId] = new window.Toolkit.SearchPanel(panel, panelOptions.value);
    if (urlQueryTerm.value) {
      showPanel(panelId);
    }
  }
});

const unwatch = watch([searchInitialized, autocompleteRef], () => {
  if (!searchInitialized.value || !autocompleteRef.value) {
    return;
  }
  if (urlQueryTerm.value) {
    setCustomQuery(urlQueryTerm.value, false);
    urlQueryTerm.value = null; // reset query after first load
  }
  unwatch();
});
</script>

<template>
  <panel
    :id="panelId"
    :classes="['search-panel']"
    :header-classes="['search-panel-header']"
    :header-bordered="false"
    :body-header-container="false"
    :init-panel="false"
    body-data-cy="search-panel-body"
    tight
    no-slide
    full
    has-scrollable-content
  >
    <template #header>
      <div class="container">
        <ul class="search-panel-header__list" role="presentation">
          <li class="search-panel-header__list-start" role="presentation">
            <div class="search-panel-header__label">
              {{ searchLabel }}
            </div>
          </li>
          <li class="search-panel-header__list-center" role="presentation"></li>
          <li class="search-panel-header__list-end" role="presentation">
            <button
              class="btn btn--bare btn--fade btn--xs btn--icon-only"
              type="button"
              data-panel-close="search-panel"
              :aria-label="closeLabel"
            >
              <span class="btn__icon btn__icon--start">
                <phx-icon icon="close" :title="closeLabel" />
              </span>
              <span class="sr-only">{{ closeLabel }}</span>
            </button>
          </li>
        </ul>
      </div>
    </template>
    <template #content>
      <div class="search-panel-form">
        <div class="container">
          <form>
            <div id="search-panel-autocomplete-wrapper">
              <phx-autocomplete
                v-if="searchInitialized"
                id="search-panel-autocomplete"
                ref="autocompleteRef"
                name="search-panel-autocomplete"
                :label="searchLabel"
                clear-button-icon="surrounded-cross"
                :clear-button-label="t('SearchPanel.clear')"
                :placeholder="t('MainNavigation.search')"
                :loading-text="t('SearchPanel.loading')"
                :max-rows="3"
                :teleport-target="{ '': '.search-panel-suggestions-placeholder' }"
                :options="suggestions"
                :close-on-input-blur="false"
                open-button-icon="magnifying-glass"
                no-result-text=""
                display-both-buttons
                min-characters="3"
                hide-label
                @autocomplete:query="onQuery"
                @autocomplete:change="onChange"
                @autocomplete:blur="onBlur"
                @autocomplete:press-enter="onEnter"
              >
              </phx-autocomplete>
            </div>
          </form>
        </div>
      </div>
      <div class="search-panel-results">
        <div class="container">
          <div class="search-panel-suggestions-placeholder"></div>
          <template v-if="searchInitialized">
            <div v-show="isQueryResultEmpty" class="search-no-result">
              <p>{{ $t(`Search.query.all.noResult`) }}</p>
            </div>
            <search-panel-empty-state
              v-show="hasEmptyState"
              :all-boutiques-image="allBoutiquesImage"
              :hits-per-type="emptyStateHitsPerType"
              @term-clicked="onTermClicked"
            >
              <template v-if="slots.contacts" #contacts>
                <slot name="contacts" />
              </template>
            </search-panel-empty-state>
          </template>
          <search-panel-results v-if="!hasEmptyState">
            <template v-if="slots.contacts" #contacts>
              <slot name="contacts" />
            </template>
          </search-panel-results>
        </div>
      </div>
    </template>
  </panel>
</template>
